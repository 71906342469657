import { format } from "date-fns";
import { useRef, useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { HistoryTimeFrame } from "../../pages/my-patrimony/history/PatrimonyHistory";
import { IncomeType } from "../../types/patrimony";
import { currency } from "../../utils/format.utils";

const Body = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .marker-value {
    font-size: 24px;
    font-weight: 600;
  }

  .marker-balances {
    display: flex;
    gap: 8px 32px;
    flex-wrap: wrap;
    height: 90px;
  }

  @media (max-width: 900px) {
    justufy-content: center;
    .marker-value {
      font-size: 20px;
    }
    .marker-balances {
      gap: 0;
      height: 50px;
      .bullet {
        font-size: 12px;
        height: 18px;
      }
    }
  }
`;

const MarkerBalance = styled.div<{ color: string }>`
  white-space: nowrap;
  width: 230px;
  display: flex;
  align-items: center;
  &.bullet:before {
    background-color: ${(s) => s.color};
  }
`;

const Wrapper = styled.div`
  margin-top: 16px;
  flex: auto;
  overflow: hidden;
  max-height: 500px;
`;

const ChartContainer = styled.div`
  border: 1px solid #bdbdbdcc;
  border-radius: 4px;
  overflow: hidden;
`;

const AxisPointsContainer = styled.div`
  padding-top: 32px;
  margin-left: 4px;
  position: relative;
  font-size: 12px;
`;

type BalanceName = "otherAssets" | "realEstateAndOtherAssets" | IncomeType;
type Balances = Record<BalanceName, number>;

type Point = Balances & Record<"x" | "index", number> & { date: string };
type StringPoint = Record<BalanceName, string>;

type ChartTheme = {
  areaColor: string;
  strokeColor: string;
};

const Themes: Record<BalanceName, ChartTheme> = {
  otherAssets: { areaColor: "#fff7c0", strokeColor: "#ffe32b" },
  realEstateAndOtherAssets: { areaColor: "#ffcf05", strokeColor: "#ffd100" },
  brut: { areaColor: "#ffbc33", strokeColor: "#ffab00" },
  net: { areaColor: "#ffbc33", strokeColor: "#ffab00" },
};

const ChartsToShow: Record<IncomeType, BalanceName[]> = {
  net: ["net"],
  brut: ["brut", "realEstateAndOtherAssets", "otherAssets"],
};

export type ChartList = { date: string } & Balances;

type ChartProps = {
  list: ChartList[];
  type: IncomeType;
  timeFrame: HistoryTimeFrame;
  hideMarker?: boolean;
};
const Chart = ({ list, type, timeFrame, hideMarker }: ChartProps) => {
  const ref = useRef<SVGSVGElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const [graph, setGraph] = useState({
    left: 0,
    right: 0,
    width: 300,
    height: 0,
  });
  const [mouseX, setMouseX] = useState(0);
  const [startedTouchIn, setStartedTouchIn] = useState(false);

  const handleResize = useCallback(() => {
    const rect = ref.current?.getBoundingClientRect();
    const graphLeft = rect?.left || 0;
    const graphRight = rect?.right || 0;
    setGraph({
      left: graphLeft,
      right: graphRight,
      width: rect?.width || 300,
      height: Math.max(
        100,
        (wrapperRef.current?.clientHeight || NaN) - 50 || 0
      ),
    });
    if (mouseX === 0 || mouseX > graphRight) setMouseX(graphRight);
    else if (mouseX - graphLeft < 0) setMouseX(graph.left);
  }, [graph.left, mouseX]);

  function mouseIsInY(clientY: number) {
    const rect = ref.current?.getBoundingClientRect();
    if (rect) {
      return rect.top < clientY && clientY < rect.bottom + 32;
    } else {
      return false;
    }
  }

  function moveMarker(clientX: number, clientY: number) {
    if (startedTouchIn || mouseIsInY(clientY)) {
      if (graph.left < clientX && clientX < graph.right) {
        // in the graph
        setMouseX(clientX);
      } else if (graph.left - 32 < clientX && clientX < graph.left) {
        // on the left
        setMouseX(graph.left);
      } else if (graph.right < clientX && clientX < graph.right + 32) {
        // on the right
        setMouseX(graph.right);
      }
    }
  }

  function handleMouseMove(ev: MouseEvent) {
    return moveMarker(ev.clientX, ev.clientY);
  }

  function handleTouchStart(ev: TouchEvent) {
    setStartedTouchIn(mouseIsInY(ev.touches[0].clientY));
  }

  function handleTouchEnd() {
    setStartedTouchIn(false);
  }

  function handleTouchMove(ev: TouchEvent) {
    if (startedTouchIn) {
      ev.preventDefault();
      moveMarker(ev.changedTouches[0].clientX, ev.changedTouches[0].clientY);
    }
  }

  useEffect(() => {
    const resizeObserver = new ResizeObserver(handleResize);
    const wrapperDiv = wrapperRef.current;
    if (wrapperDiv) {
      resizeObserver.observe(wrapperDiv);
      return () => resizeObserver.unobserve(wrapperDiv);
    }
  }, [handleResize]);

  useEffect(() => {
    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("touchmove", handleTouchMove, { passive: false });
    window.addEventListener("touchstart", handleTouchStart);
    window.addEventListener("touchend", handleTouchEnd);
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("touchmove", handleTouchMove);
      window.removeEventListener("touchstart", handleTouchStart);
      window.removeEventListener("touchend", handleTouchEnd);
    };
  });

  if (list.length === 0) {
    return null;
  }

  if (list.length === 1) {
    list.push(list[0]);
  }

  const startDate = new Date(list[0].date);
  const endDate = new Date(list[list.length - 1].date);

  const axisPoints: Array<{ label: string; x: number }> = [];

  let currentDate = new Date(startDate);

  while (currentDate.getTime() < endDate.getTime()) {
    axisPoints.push({
      x:
        (graph.width * (currentDate.getTime() - startDate.getTime())) /
        (endDate.getTime() - startDate.getTime()),
      label: format(currentDate, timeFrame === "UP_TO_1_MONTH" ? "d" : "MMM"),
    });

    if (timeFrame === "UP_TO_1_MONTH") {
      currentDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() + 7
      );
    } else if (timeFrame === "UP_TO_3_MONTHS") {
      currentDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        1
      );
    } else if (timeFrame === "UP_TO_6_MONTHS") {
      currentDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 2,
        1
      );
    } else if (timeFrame === "UP_TO_1_YEAR") {
      currentDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 3,
        1
      );
    } else {
      currentDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 6,
        1
      );
    }
  }

  const maxLabelSize = timeFrame === "UP_TO_1_MONTH" ? 15 : 32;
  const graduationsMarginLeft = 4;
  const labelOffset = maxLabelSize + graduationsMarginLeft;

  // remove labels that may overflow
  if (axisPoints[0]) {
    if (axisPoints[1] && axisPoints[0].x + labelOffset >= axisPoints[1].x) {
      axisPoints[0].label = "";
    }
    if (axisPoints[axisPoints.length - 1].x + labelOffset >= graph.width) {
      axisPoints[axisPoints.length - 1].label = "";
    }
  }

  function getMinBalance() {
    return type === "net"
      ? Math.min(...list.map((x) => x.net))
      : Math.min(...list.map((x) => x.otherAssets));
  }

  function getMaxBalance() {
    return Math.max(...list.map((x) => x[type]));
  }

  function getMaxChart() {
    const maxBalance = getMaxBalance();
    const minBalance = getMinBalance();
    const isNegative = maxBalance < 0;
    const factor = 0.2;
    let maxChart =
      maxBalance +
      (isNegative ? -factor : factor) *
        (isNegative ? maxBalance : maxBalance - minBalance);
    if (isNegative && maxChart > 0) maxChart = 0;
    return maxChart;
  }

  function getMinChart() {
    const maxBalance = getMaxBalance();
    const minBalance = getMinBalance();

    const isPositive = minBalance >= 0;
    const factor = 0.1;
    let minChart =
      minBalance +
      (isPositive ? -factor : factor) *
        (isPositive ? maxBalance - minBalance : minBalance);
    if (isPositive && minChart < 0) minChart = 0;
    return minChart;
  }

  const maxChart = getMaxChart();
  const minChart = getMinChart();

  function y(balance: number) {
    return graph.height * (1 - (balance - minChart) / (maxChart - minChart));
  }

  const points = list.map((p, index): Point => {
    return {
      x:
        ((new Date(p.date).getTime() - startDate.getTime()) /
          (endDate.getTime() - startDate.getTime())) *
        graph.width,
      otherAssets: y(p.otherAssets),
      realEstateAndOtherAssets: y(p.realEstateAndOtherAssets),
      brut: y(p.brut),
      net: y(p.net),
      index,
      date: p.date,
    };
  });

  function sp(acc: StringPoint, p: Point, balanceName: BalanceName) {
    return `${acc[balanceName]} ${p.x},${p[balanceName]}`;
  }

  const stringPoints = points.reduce(
    (acc, p): StringPoint => ({
      otherAssets: `${sp(acc, p, "otherAssets")}`,
      realEstateAndOtherAssets: `${sp(acc, p, "realEstateAndOtherAssets")}`,
      brut: `${sp(acc, p, "brut")}`,
      net: `${sp(acc, p, "net")}`,
    }),
    { otherAssets: "", realEstateAndOtherAssets: "", brut: "", net: "" }
  );

  const markerX = mouseX - graph.left;
  const markerSupposedPoint = points.find((el) => el.x >= markerX);
  const markerPointIndex = markerSupposedPoint
    ? markerSupposedPoint.index || 1
    : points.length - 1;

  const markerPointBefore = points[markerPointIndex - 1];
  const markerPointAfter = points[markerPointIndex];

  const ratio =
    (markerX - markerPointBefore.x) /
    (markerPointAfter.x - markerPointBefore.x);

  const marker = ratio > 0.5 ? markerPointAfter : markerPointBefore;

  function balanceMarker(balanceName: BalanceName) {
    const previousBalance = list[markerPointIndex - 1][balanceName];
    const balance = list[markerPointIndex][balanceName];
    return ratio > 0.5 ? balance : previousBalance;
  }

  const markerBalance = {
    otherAssets: balanceMarker("otherAssets"),
    realEstateAndOtherAssets: balanceMarker("realEstateAndOtherAssets"),
    brut: balanceMarker("brut"),
    net: balanceMarker("net"),
  };

  const zeroY = (maxChart / (maxChart - minChart)) * graph.height;

  const isMobile = () => graph.width <= 550;

  const markerDate = new Date(new Date(marker.date).getTime());

  return (
    <Body>
      <div className="marker-date">{format(markerDate, "d MMMM yyyy")}</div>
      <div className="marker-value">{currency(markerBalance[type])}</div>

      <div
        className="marker-balances"
        style={{
          transition: "0.2s",
          //   overflow: "hidden",
          ...(type === "net" ? { opacity: 0 } : {}),
          display: hideMarker ? "none" : "flex",
        }}
      >
        <MarkerBalance color={Themes.brut.strokeColor} className="bullet">
          Épargne :{" "}
          {currency(
            markerBalance.brut - markerBalance.realEstateAndOtherAssets
          )}
        </MarkerBalance>
        <MarkerBalance
          color={Themes.realEstateAndOtherAssets.strokeColor}
          className="bullet"
        >
          Immobilier :{" "}
          {currency(
            markerBalance.realEstateAndOtherAssets - markerBalance.otherAssets
          )}
        </MarkerBalance>
        <MarkerBalance
          color={Themes.otherAssets.strokeColor}
          className="bullet"
        >
          Autres biens : {currency(markerBalance.otherAssets)}
        </MarkerBalance>
      </div>

      <Wrapper ref={wrapperRef}>
        <ChartContainer>
          <svg
            ref={ref}
            viewBox={`0 0 ${graph.width} ${graph.height}`}
            xmlns="http://www.w3.org/2000/svg"
          >
            {/* Areas */}
            {ChartsToShow[type].map((balanceName) => (
              <polygon
                key={balanceName}
                points={`0,${zeroY} ${stringPoints[balanceName]} ${graph.width},${zeroY}`}
                fill={Themes[balanceName].areaColor}
              />
            ))}
            {/* x-Axis */}
            <line
              x1={0}
              y1={zeroY}
              x2={graph.width}
              y2={zeroY}
              stroke="#bdbdbd"
              strokeWidth={0.5}
            />
            {/* Curves */}
            {ChartsToShow[type]
              .slice()
              .reverse()
              .map((balanceName) => (
                <polyline
                  key={balanceName}
                  points={stringPoints[balanceName]}
                  stroke={Themes[balanceName].strokeColor}
                  strokeWidth={2}
                  fill="none"
                />
              ))}
            {/* Grid */}
            {axisPoints.map(({ x }) => (
              <line
                key={x}
                x1={x}
                y1={0}
                x2={x}
                y2={graph.height}
                stroke="#bdbdbd"
                strokeWidth={0.4}
              />
            ))}
            {/* Marker */}
            <line
              x1={marker.x}
              y1={marker[type]}
              x2={marker.x}
              y2={zeroY}
              stroke="black"
              strokeWidth={1.5}
            />
            <circle
              cx={marker.x}
              cy={marker[type]}
              r={isMobile() ? 5 : 8}
              fill="black"
            />
          </svg>
          {/* Graduations */}
          <AxisPointsContainer>
            {axisPoints.map(({ label, x }) => (
              <span key={x} style={{ position: "absolute", left: x, top: 0 }}>
                {label}
              </span>
            ))}
          </AxisPointsContainer>
        </ChartContainer>
      </Wrapper>
    </Body>
  );
};

export default Chart;
