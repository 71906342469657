import { useCallback, useEffect, useState } from "react";
import Chart, { ChartList } from "../../components/charts/Chart";
import { Transaction } from "../../types/movements";
import { HistoryTimeFrame } from "./history/PatrimonyHistory";
import { NavPillsContainer } from "./Patrimony";
import NavPills from "../../components/NavPills";
import Loader from "../../components/Loader";

const timeFrameOptions: Array<{
  id: HistoryTimeFrame;
  label: string;
  miniLabel?: string;
}> = [
    { id: "UP_TO_1_MONTH", label: "1 mois", miniLabel: "1 mois" },
    { id: "UP_TO_3_MONTHS", label: "3 mois", miniLabel: "3m" },
    { id: "UP_TO_6_MONTHS", label: "6 mois", miniLabel: "6m" },
    { id: "UP_TO_1_YEAR", label: "1 an", miniLabel: "1 an" },
    { id: "UP_TO_3_YEARS", label: "3 ans", miniLabel: "3a" },
    { id: "ALL", label: "Tout" },
  ];

type SavingChartProps = {
  transactions: Transaction[];
};

const SavingChart = (props: SavingChartProps) => {
  const [timeFrame, setTimeFrame] = useState<HistoryTimeFrame>("ALL");
  const [allTimeFramesData, setAllTimeFramesData] =
    useState<Map<HistoryTimeFrame, ChartList[]>>();

  const loadAllTimeFramesData = useCallback(() => {
    const timeFrames: HistoryTimeFrame[] = timeFrameOptions.map((x) => x.id);

    for (const timeFrame of timeFrames) {
      let filteredTransactions = filterTransactionByTimeFrame(
        props.transactions,
        timeFrame
      );

      const overAll = filteredTransactions.reverse().map((x): ChartList => {
        const brut = x.value;
        return {
          date: x.date,
          otherAssets: 0,
          realEstateAndOtherAssets: 0,
          brut: brut ? brut : 0,
          net: brut ? brut : 0,
        };
      });

      if (overAll.length === 1) {
        overAll.push({
          ...overAll[0],
          date: `${new Date()}`,
        });
      }

      setAllTimeFramesData((prevData) => {
        const newData = new Map(prevData);
        newData.set(timeFrame, overAll);
        return newData;
      });
    }
  }, [props.transactions]);

  useEffect(() => {
    loadAllTimeFramesData();
  }, [loadAllTimeFramesData, timeFrame]);

  const filterTransactionByTimeFrame = (
    transactions: Transaction[],
    timeFrame: HistoryTimeFrame
  ) => {
    return transactions.filter((t) => {
      const date = new Date(t.date);
      const now = new Date();
      switch (timeFrame) {
        case "UP_TO_1_MONTH":
          return date > new Date(now.setMonth(now.getMonth() - 1));
        case "UP_TO_3_MONTHS":
          return date > new Date(now.setMonth(now.getMonth() - 3));
        case "UP_TO_6_MONTHS":
          return date > new Date(now.setMonth(now.getMonth() - 6));
        case "UP_TO_1_YEAR":
          return date > new Date(now.setFullYear(now.getFullYear() - 1));
        case "UP_TO_3_YEARS":
          return date > new Date(now.setFullYear(now.getFullYear() - 3));
        case "ALL":
          return true;
        default:
          return false;
      }
    });
  };

  return (
    <div>
      <NavPillsContainer>
        <NavPills
          options={timeFrameOptions}
          onOptionSelected={setTimeFrame}
          selectedOptionId={timeFrame}
          widthMini="410px"
        />
      </NavPillsContainer>
      <div>
        {!allTimeFramesData || !allTimeFramesData.get(timeFrame) ? (
          <Loader />
        ) : (
          <Chart
            type="net"
            timeFrame={timeFrame}
            list={allTimeFramesData.get(timeFrame) || []}
            hideMarker={true}
          />
        )}
      </div>
    </div>
  );
};

export default SavingChart;
